import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'

const Container = styled.footer`
    width: 100%;
    position: fixed;
    bottom: 0;
`

const Inner = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 980px;
    margin: 0 auto;
    padding: 40px 20px;

`
const LinksContainer = styled.div`
    display: flex;
    gap: 20px;
`

const Link = styled.a`
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
`

const Footer = () => {
    return (
        <Container>
            <Inner>
                <GatsbyLink rel="noreferrer noopener" to="/" style={{ color: '#FFFFFF', fontSize: '14px', textDecoration: 'none' }}>© {new Date().getFullYear()} JUDY LEE</GatsbyLink>
                
                <LinksContainer>
                    <Link rel="noreferrer noopener" target="_blank" href="https://www.linkedin.com/in/judylee73/">LINKEDIN</Link>
                    {/* <Link rel="noreferrer noopener" target="_blank" href="https://www.instagram.com/judyylee73/">INSTAGRAM</Link> */}
                </LinksContainer>
            </Inner>
        </Container>
    )
}

export default Footer
