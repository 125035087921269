/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"

import "@fontsource/dm-sans"

import Header from "./header"
import Footer from './footer'
import "./layout.css"

export const Root = createGlobalStyle`
  body {
    font-family: 'DM Sans';
    background-color: #5e81a4;
    color: #FFFFFF;
  }
`

const Container = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const Main = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Root/>

      {/* <Header siteTitle={data.site.siteMetadata.title || 'Judy Lee'} /> */}
      <Container>
        <Main>{children}</Main>
      </Container>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
